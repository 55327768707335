@import 'components/variables';

html {
  scroll-behavior: smooth !important;
}

.pagination {
  &-item {
    border: 1px solid rgba($black, 0.1);
    padding: 0.7rem 1rem;
    @apply bg-muted text-foreground;
  }
  .break {
    a {
      border: 1px solid rgba($black, 0.1);
      padding: 0.7rem 1.25rem;
      @apply bg-muted text-foreground;
    }
  }
  .selected {
    a {
      @apply bg-foreground text-muted;
    }
  }
  .previous {
    a {
      border: 1px solid rgba($black, 0.1);
      padding: 0.7rem 1rem;
      @apply bg-muted text-foreground;
    }
  }
  .next {
    a {
      border: 1px solid rgba($black, 0.1);
      padding: 0.7rem 1rem;
      @apply bg-muted text-foreground;
    }
  }
}
