@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "../variables";

* {
  list-style: none;
  text-decoration: none !important;
}

body {
  font-family: $font;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 4px;
  @apply bg-foreground;
}

::-webkit-scrollbar-thumb {
  @apply bg-muted;
  border-radius: 4px;
}

ul,
p {
  padding: 0;
  margin: 0;
}

.main {
  position: relative;
  z-index: 2;
}

/* Snowfall qatlam */
.snowfall {
  pointer-events: none; /* Qor parchalariga foydalanuvchi harakati ta’sir qilmaydi */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999; /* Eng yuqori qatlam */
  overflow: hidden;
}

/* Qor parchasi uslubi */
.snowflake {
  position: absolute;
  top: -10%; /* Animatsiya boshlanish joyi */
  color: white;
  animation: fall linear infinite; /* Tushish animatsiyasi */
  opacity: 0.8;
}

@keyframes fall {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(110vh); /* Ekrandan tashqariga tushadi */
  }
}

@import "../EduLinks/eduLinks";
@import "../Footer/footer";
@import "../Gallery/gallery";
@import "../Home/home";
@import "../Links/links";
@import "../News/news";
@import "../Placeholder/placeholder";
@import "../PreLoader/preLoader";
@import "../RightBar/right-bar";
@import "../Statistika/statistika";
