@import "../variables";

.statistic {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-image: linear-gradient(rgba($black, 0.6), rgba($black, 0.6)),
    url("../../assets/images/bg.webp");
  background-size: cover;
  background-position: center;
  &-item {
    padding: 1rem;
    backdrop-filter: blur(6px);
    background-color: rgba($white, 0.03);
    border-radius: 0.4rem;
    box-shadow: 0 0 10px rgba($white, 0.3);
    text-align: center;
    position: relative;
    overflow: hidden;
    color: $white;
    &-img {
      width: 100px;
      filter: invert(100%);
    }
    &-overlay {
      position: absolute;
      width: 70%;
      left: -20%;
      top: 0;
      opacity: 0.08;
      filter: invert(100%);
    }
    &-title {
      font-size: 1.25rem;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-number {
      font-size: 2.4rem;
      font-weight: 600;
    }
  }
}
@media screen and (max-width: 768px) {
  .statistic {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-image: none;
    background-color: rgba($black, 0.9);
  }
}
