@import "../variables";

.year-img {
  width: 100%;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 0.3rem;
  box-shadow: 0 0 15px rgba($black, 0.2);
  img {
    width: 100%;
  }
}
.year-title {
  text-align: center;
}
.widget {
  padding: 1rem;
  border-radius: 0.3rem;
  box-shadow: 0 0 15px rgba($black, 0.2);
  &-title {
    position: relative;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 4px;
      background-color: $first-color;
      top: 30px;
      border-radius: 2px;
      left: 0;
    }
  }
  &-item {
    border-bottom: 1px solid $first-color;
    padding-bottom: 1rem;
    &-img {
      width: 100%;
      height: 100px;
      object-fit: cover;
    }
    &-title {
      color: $first-color;
    }
    &-icons {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      span {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: smaller;
        img {
          width: 15px;
        }
      }
    }
    &-btn {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.4rem 1rem;
      background-color: $first-color;
      color: $white;
      border-radius: 0.3rem;
      transition: 0.4s ease;
      img {
        width: 20px;
        filter: invert(100%);
        transition: 0.4s ease;
      }
      &:hover {
        img {
          margin-left: 10px;
          filter: invert(0);
        }
        color: $first-color;
        background-color: transparent;
        box-shadow: 0 0 5px $first-color;
      }
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
    &:not(:first-child) {
      padding-top: 1rem;
    }
  }
}
