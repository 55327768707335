@import "../variables";

.swiperOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.01) 45%, rgba(0, 0, 0, 0.6) 80%);
  z-index: 11;
  top: 0;
}

.newsOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.01) 35%, rgba(0, 0, 0, 0.8) 80%);
  z-index: 11;
  top: 0;
}

.newsSwiper {
  position: relative;
  height: 550px;
  .swiper-slide {
    height: 550px;
    position: relative;
    width: 100%;
  }
}
