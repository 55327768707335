@import "../variables";

.linksSwiper {
  padding: 1rem;
  .swiper-slide {
    .link-item {
      position: relative;
      background-color: lighten($black, 80%);
      border-radius: 1rem;
      overflow: hidden;
      &-img {
        position: relative;
        height: 170px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 140px;
        }
      }
      &-body {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 0px;
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: rgba($black, 0.7);
        backdrop-filter: blur(5px);
        color: $white;
        transition: 0.3s ease;
      }
      &:hover {
        .link-item-body {
          height: 80px;
          visibility: visible;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .linksSwiper {
    .swiper-slide {
      .link-item {
        &-body {
          height: 80px;
          visibility: visible;
        }
      }
    }
  }
}
