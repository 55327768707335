@import '../variables';

.ringLoader {
  .ring {
    width: 300px;
    height: 300px;
    border: 0px solid $first-color;
    border-radius: 50%;
    position: absolute;
  }

  .ring:nth-child(1) {
    border-bottom-width: 8px;
    border-color: rgba(255, 0, 255);
    animation: rotate1 2s linear infinite;
  }

  .ring:nth-child(2) {
    border-right-width: 8px;
    border-color: rgba(0, 247, 255);
    animation: rotate2 2s linear infinite;
  }

  .ring:nth-child(3) {
    border-top-width: 8px;
    border-color: rgba(0, 255, 13);
    animation: rotate3 2s linear infinite;
  }

  .loading {
    font-weight: 700;
    font-size: 2.25rem;
  }
}

@keyframes rotate1 {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate3 {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@media screen and (max-width: 992px) {
  .ring {
    width: 200px;
    height: 200px;
  }
  .loading {
    font-weight: 700;
    font-size: 1.35rem;
  }
}

.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
