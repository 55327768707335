@import '../variables';

.scroll-up {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
  border-radius: 0.5rem;
  padding: 0.6rem;
  transition: 0.3s ease;
  transform: translateY(200px);
  img {
    transition: 0.3s ease;
    width: 30px;
  }
  &:hover {
    box-shadow: none;
  }
}

.show-scroll {
  transform: translateY(0);
}
