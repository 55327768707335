@import "../variables";

.footer {
  margin-top: 7rem;
  padding: 10rem 0 3rem;
  background-color: $first-color;
  position: relative;
  bottom: 0;
  z-index: 0;
  &-header {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem 3rem;
    background-color: rgba($white, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    box-shadow: 0 0 20px $black;
    &-title {
      font-size: 1.5rem;
      font-weight: 700;
    }
    &-social {
      display: flex;
      align-items: center;
      &-link {
        display: inline-flex;
        margin: 0 1rem;
        img {
          width: 60px;
        }
      }
    }
  }
  &-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    img {
      width: 40%;
      margin-bottom: 1rem;
    }
    h4 {
      color: $white;
      text-transform: uppercase;
    }
  }
  &-contact {
    p {
      color: $white;
      display: flex;
      align-items: center;
      gap: 1rem;
      font-weight: 300;
      margin-bottom: 1rem;
      img {
        width: 35px;
        filter: invert(100%);
      }
    }
  }
  &-social {
    img {
      width: 100%;
      height: 100px;
      object-fit: cover;
    }
  }
  &-end {
    margin-top: 2rem;
    color: $white;
    p {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin-bottom: 1rem;
      font-weight: 200;
      img {
        filter: invert(100%);
        width: 20px;
      }
    }
    .col-lg-11 {
      padding: 2rem 0 0;
      border-top: 2px solid $white;
    }
  }
  &-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: $white;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding-top: 3rem;
    position: relative;
    &-img {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      img {
        width: 25%;
        margin-bottom: 1rem;
      }
      h4 {
        color: $white;
        text-transform: uppercase;
      }
    }
  }
}
