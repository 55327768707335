.edulinks {
  &-icon {
    width: 70px;
  }
  &:hover {
    .edulinks-overlay {
      opacity: 0.08;
    }
    .edulinks-title {
      color: #fff;
    }
  }
}
