.gallery {
  div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.2rem;
    a {
      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .gallery {
    div {
      grid-template-columns: repeat(2, 1fr);
      padding: 0.2rem;
    }
  }
}
