.newsPlaceholder {
  width: 100%;
  height: 40vh;
}

.sliderPlaceholder {
  width: 100%;
  height: 90vh;
}

.menuPlaceholder {
  height: 1.5rem;
  width: 70%;
  margin-left: 8%;
  &-list {
    display: flex;
    justify-content: space-between;
    span {
      height: 1.5rem;
    }
  }
}

.adsPlaceholder {
  height: 30vh;
  width: 100%;
}

.smallAdsPlaceholder {
  height: 120px;
  width: 100%;
}

.pagesPlaceholder {
  width: 100%;
  height: 250px;
}

.breadcrumbPlaceholder {
  width: 100%;
  height: 300px;
}

.rightPlaceholder {
  height: 150px;
  width: 100%;
}

.yearPlaceholder {
  width: 100%;
  height: 300px;
  margin-bottom: 2rem;
}
